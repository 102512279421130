import '../../styles/pages/fall-winter-2023/an-educator-first.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
// import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2023-stories/more-stories"

import Hero from '../../images/fall-winter-2023/an-educator-first/hero-bg.jpg';
import Alva from '../../images/fall-winter-2023/an-educator-first/alva.png';
import AlvaStudents from '../../images/fall-winter-2023/an-educator-first/alva-students.jpg';
import alvaPromenade from '../../images/fall-winter-2023/an-educator-first/alva-promenade.jpg'
import socialBanner from '../../images/fall-winter-2023/social-banners/an-educator-first.jpg';

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'An Educator First';
    var pageDesc = 'Professor and higher education administrator Sylvia Alva steps into role as president of Cal State Fullerton.';
    var slug = 'an-educator-first';

    var heroStyles = {
      backgroundImage: `url(${Hero})`,
    }

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2023">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`fall-winter-2023 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s">{pageTitle}</h1>
            <p className="sub-heading animated fadeInDown delay-1s">{pageDesc}</p>
            <span className="animated fadeInDown delay-1s byline">By Valerie Orleans</span>
          </div>
          <img src={Alva} alt="" id="alva-hero" />
        </div>
        <article className='wrap' id="copy-wrap">

          <div className='wrap small'>
            <blockquote className='align-left align-outside'>
              <p>Be bold and dream big.</p>
              <cite>- Sylvia Alva</cite>
            </blockquote>
  
            <p><span className="intro-text">Among the many higher education roles <a href="https://news.fullerton.edu/2023/04/sylvia-a-alva-appointed-interim-president-of-cal-state-fullerton/" target='_blank' rel='noreferrer'>Sylvia Alva</a></span> has served, she considers herself an educator first. On Aug. 1, she returned to Cal State Fullerton, where she began her career as an assistant professor of adolescent development, to serve as the university’s president.</p>
  
            <p>Here, she’s on a mission to encourage students to “be bold and dream big.” It’s the kind of motivation and empowerment she received as a first-generation graduate of the California State University system.</p>
  
            <p>Born and raised in Los Angeles, Alva didn’t always have counselors and mentors who encouraged her to go to college. When she told her high school counselor she didn’t know how she would pay for her education, he advised her to forego college and enter the workforce. Fortunately, her Spanish teacher reached out and assisted her with her college and financial applications. Experiences such as these have served as the purpose and motivation that continue to shape her life and leadership. </p>
  
            <p>“As educators, we have a unique role and responsibility to maximize the gifts, talents and brilliance within our students and communities,” she says. “One of the reasons I love the CSU is that its mission has always been about access and opportunity. Reflecting on my own college experience, my professors encouraged me to get involved in undergraduate research opportunities and pursue a doctoral education after completing my degree.</p>
  
            <p>“They saw in me something that I did not see in myself at the time,” she continues. “With their support, they made the improbable possible.”</p>
  
            <p>After Alva graduated from Cal State Los Angeles with a bachelor’s degree in psychology, she went on to earn her master’s degree and Ph.D. in psychology from UCLA. One could say the CSU is built into Alva’s family’s DNA. Her husband, Salvador, and their two adult daughters, Raquel and Adriana, are all CSU alumni.</p>
  
            <p>“Getting my CSU degree and earning a Ph.D. changed the course of my life and opened many doors of opportunity, including the privilege and honor to serve as the president of Cal State Fullerton,” she says. “It’s one of the reasons I’m so grateful for the work our faculty and staff do in mentoring, supporting and encouraging students.”</p>

          </div>
          <figure>
            <img src={AlvaStudents} alt='President Alva stands with a group of students' />
          </figure>
          <div className='wrap small'>
  
            <h2>A Career in the CSU</h2>
            <p>At Cal State Fullerton, Alva was promoted from assistant to full professor. She also served as the educational equity coordinator for the <a href="https://hhd.fullerton.edu/" target='_blank' rel='noreferrer'>College of Health and Human Development</a>, the all-university director of the academic advising center and culminated her nearly 20 years at Cal State Fullerton as the associate vice president for undergraduate programs. </p>
  
            <p>“Cal State Fullerton is a bold, transformative university with outstanding faculty, staff and students who understand education’s role in advancing our society, empowering communities and changing lives,” reflects Alva. “I share that Titan spirit and am excited to help advance that collective vision.”</p>
  
            <p>Alva continued her career as dean of the College of Health and Human Development at Cal State Northridge and later, provost and vice president for academic affairs at Cal Poly Pomona. </p>
  
            <p>As executive vice chancellor of academic and student affairs in the CSU Office of the Chancellor, Alva provided administrative leadership, strategic direction, and support for a wide array of academic and student policies, initiatives and programs in the Division of Academic and Student Affairs. Along with her leadership team, they led the CSU’s signature graduation initiative and efforts to close equity gaps.</p>
  
            <h2>A Titan Returns</h2>

            <figure className='align-left extra-large' id='alva-promenade'>
              <img src={alvaPromenade} alt="President Alva walks down Titan Promenade" />
              <figcaption className='center' style={{border: 'none'}}>President Sylvia Alva</figcaption>
            </figure>

            <p>When Alva began college, she wanted to become a high school Spanish teacher. She took a different path, but still sees herself as an educator and lifelong learner.</p>
  
            <p>“When I meet people for the first time, especially outside of the work context, I introduce myself as an ‘educator,’” she explains. “It’s also why I challenge myself every year to tap into the beginner’s mindset and learn a new skill or try something I’ve never done before.”</p>
  
            <p>Kayaking, crocheting and hiking new trails are among her latest accomplishments and next on her list is learning to use American sign language to recite the Pledge of Allegiance.</p>
  
            <p>At Cal State Fullerton, she’s looking forward to helping the university build on its successes, connecting with former colleagues, making new friends and even rediscovering the “delicious lemon bars” she remembers from campus events.</p>
  
            <p>Alva recognizes the essential role that faculty and staff play in preparing Titans for personal and professional success.</p>
  
            <p>“One of the core tenets of my personal journey and model of leadership is the conscious choice and commitment to serve others,” shares Alva. “Student success continues to be a top priority,  and I see an even greater emphasis being given to a holistic and inclusive approach to achieving this success.”</p>
  
            <p>She believes one of the hallmarks of Cal State Fullerton is its “can do” spirit, which is passed onto each new generation of students.</p>
  
            <p>“The university’s size, reputation, stature and impact have notably increased since I taught here. The university and its alumni have matured into a powerful force and engine of social mobility in Southern California.” <span className="end-mark" /></p>

            <p className="learn-more"><a href="https://president.fullerton.edu/" target='_blank' rel='noreferrer'>president.fullerton.edu</a></p>
          </div>

        </article>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 